@font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaBoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaSemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaSemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lufga';
    src: url('../../assets/fonts/LufgaThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }